import {
  Container,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  IconButton,
  Box,
  TablePagination,
} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Scrollbar from 'src/components/Scrollbar';
import useTable, { emptyRows } from 'src/hooks/useTable';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
  TableSkeleton,
} from 'src/components/table';
import Iconify from 'src/components/Iconify';
import { ChangeEvent, useEffect, useState } from 'react';

import axios from 'src/utils/axios';
import OrdersTableRow from './OrdersTableView';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import TransactionToolbar from './TransactionsToolbar';

// ----------------------------------------------------------------------

export const TABLE_HEAD = [
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'stripe_id', label: 'Stripe Id', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'order_id', label: 'Order Code' },
  { id: 'created_at', label: 'Created at', align: 'left' },
  // { id: '' },
];

export default function TransactionContainer() {
  const { themeStretch } = useSettings();
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    limit: 10,
    ofset: 0,
    currentPage: 1,
  });

  const [filter, setFilter] = useState({
    search: '',
    status: 'all',
  });

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    // onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: pagination.limit,
  });

  const isNotFound = !(transactions || []).length;
  const denseHeight = dense ? 60 : 80;

  useEffect(() => {
    async function getListTransactions() {
      const { data } = await axios.get('/transactions');
      setTransactions(data.data);
      setTotal(data.total);
    }
    getListTransactions();
  }, []);

  const handleChangePage = async (_: unknown, newPage: number) => {
    // if (newPage + 1 > pagination.currentPage) {
    setPagination((pre) => ({
      ...pre,
      currentPage: newPage,
    }));
    try {
      const statusdata = filter.status === 'all' ? '' : `&status=${filter.status}`;
      const searchdata = filter.search ? `&search=${filter.search}` : '';
      const { data } = await axios.get(
        `/transactions?limit=${pagination.limit}&offset=${
          pagination.limit * newPage
        }${searchdata}${statusdata}`
      );
      setTransactions((pre) => pre.concat(data.data));
    } catch (e) {}
    // }

    onChangePage(_, newPage);
  };

  const handleChangeSize = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const statusdata = filter.status === 'all' ? '' : `&status=${filter.status}`;
      const searchdata = filter.search ? `&search=${filter.search}` : '';
      const { data } = await axios.get(
        `/transactions?limit=${parseInt(e.target.value, 10)}&offset=0 ${searchdata}${statusdata}`
      );
      setTransactions(data.data);
      setPagination({ limit: parseInt(e.target.value, 10), ofset: 0, currentPage: 1 });
    } catch (e) {}
    onChangeRowsPerPage(e);
  };

  const hanldeApplyFilter = async () => {
    setPagination((pre) => ({
      ...pre,
      currentPage: 0,
    }));
    onChangePage(null, 0);

    setFilter({
      search,
      status,
    });

    const statusdata = status === 'all' ? '' : `&status=${status}`;
    const searchdata = search ? `&search=${search}` : '';
    const { data } = await axios.get(`/transactions?limit=10&offset=0${searchdata}${statusdata}`);
    setTransactions(data.data);
    setTotal(data.total);
  };
  return (
    <Page title="Transaction Managements">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Transaction Management"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Transaction Management', href: '/dashboard/orders' },
          ]}
        />
        <Box
          sx={{
            boxShadow:
              'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
            borderRadius: '16px',
          }}
        >
          <TransactionToolbar
            search={search}
            status={status}
            onFilterSearch={(newValue) => {
              setSearch(newValue);
            }}
            onFilterStatus={(event) => {
              setStatus(event.target.value);
            }}
            hanldeApplyFilter={hanldeApplyFilter}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 960, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={transactions.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      transactions.map((row: any) => row)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => {}}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={transactions.length}
                  numSelected={selected.length}
                  // onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     transactions.map((row: any) => row)
                  //   )
                  // }
                  sx={{ backgroundColor: '#F4F6F8' }}
                />

                <TableBody>
                  {transactions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index) =>
                      row ? (
                        <OrdersTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={(e) => {
                            onSelectRow(row.id);
                            e.stopPropagation();
                          }}
                          onDeleteRow={(e) => {}}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, transactions.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeSize}
            />
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
