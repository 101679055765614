import { Stack, InputAdornment, TextField, MenuItem, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
// components

// ----------------------------------------------------------------------

type Props = {
  search: string;
  status: string;
  onFilterSearch: (value: string) => void;
  onFilterStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hanldeApplyFilter: () => void;
};

const optionsStatus = ['all', 'succeeded', 'Failed'];
export default function TransactionToolbar({
  search,
  status,
  onFilterSearch,
  onFilterStatus,
  hanldeApplyFilter,
}: Props) {
  return (
    <Stack spacing={2} sx={{ py: 3.5, px: 1 }} direction={'row'}>
      <TextField
        fullWidth
        select
        label="Status"
        value={status}
        onChange={onFilterStatus}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: 300 },
          textTransform: 'capitalize',
        }}
      >
        {optionsStatus.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        fullWidth
        value={search}
        onChange={(event) => onFilterSearch(event.target.value)}
        placeholder="Search by Order Code or email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Button
        size="small"
        onClick={() => hanldeApplyFilter()}
        startIcon={<Iconify icon={'eva:search-outline'} />}
      >
        Search
      </Button>
    </Stack>
  );
}
