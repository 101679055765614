import Page from 'src/components/Page';
import TransactionContainer from 'src/container/Transactions';

const TransactionManagement = () => (
  <Page title="Transaction Managements">
    <TransactionContainer />
  </Page>
);

export default TransactionManagement;
