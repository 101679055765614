import { useNavigate } from 'react-router-dom';
// @mui
import { TableRow, TableCell, Typography } from '@mui/material';

import Label from 'src/components/Label';

// ----------------------------------------------------------------------

type Props = {
  row: any;
  selected: boolean;
  onSelectRow: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteRow: (event: React.MouseEvent<HTMLElement>) => void;
};

export default function OrdersTableRow({ row, selected, onSelectRow, onDeleteRow }: Props) {
  const { amount, order, stripe_id, status, created_at } = row;

  const navigate = useNavigate();

  const onClickRow = (id: string) => {
    navigate(`/dashboard/order_details/${id}`);
  };

  return (
    <TableRow
      hover
      selected={selected}
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        onClickRow(order.id);
      }}
    >
      <TableCell>
        <Typography variant="body2" noWrap>
          {order?.email}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2">{stripe_id}</Typography>
      </TableCell>

      <TableCell align="right">
        <Typography
          variant="body2"
          noWrap
          style={{
            color: '#2065d1',
          }}
        >
          د.إ {amount.toFixed(2)}
        </Typography>
      </TableCell>

      <TableCell>
        <Label
          variant="ghost"
          color={status === 'succeeded' ? 'success' : status === 'unpaid' ? 'error' : 'warning'}
          sx={{ textTransform: 'capitalize', mr: '16px' }}
        >
          {status}
        </Label>
      </TableCell>

      <TableCell align="left">
        <Typography variant="body2" noWrap>
          {order?.code || '___'}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography variant="body2" noWrap>
          {new Date(created_at).toLocaleDateString()}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
