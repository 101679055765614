import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import TransactionManagement from 'src/pages/transactions';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace />, index: true },
        { path: 'app', element: <Dashboard /> },
        { path: 'users', element: <UserManagement /> },
        { path: 'staff_users', element: <StaffUsers /> },
        { path: 'orders', element: <OrderManagement /> },
        { path: 'card_categories', element: <CardCategories /> },
        { path: 'card_categories/:id/new_card/', element: <NewCardPage /> },
        { path: 'card_by_category/:id', element: <CardCategoriesDetails /> },
        { path: 'card_by_category/:id/:cardId/edit', element: <EditCardId /> },
        { path: 'card_by_category/:id/:cardId/card_color', element: <EditCardId /> },
        { path: 'order_details/:id', element: <OrdersDetails /> },
        { path: 'transactions', element: <TransactionManagement /> },
        { path: 'card_profile', element: <CardProfile /> },
        { path: 'shipping_method', element: <ShippingMethod /> },
        { path: 'all_products', element: <AllProductsPage /> },
        { path: 'social_media', element: <SoicalMediaGroup /> },
        { path: 'social_media/:id', element: <SocialMedia /> },
        { path: 'contact', element: <ContactPage /> },
        { path: 'material-colors', element: <ColorsMaterialPage /> },
        { path: 'company-setting', element: <SettingPage /> },
        { path: 'notifications', element: <NotificationsPage /> },
        { path: 'marketing-email', element: <MarketingEmailPage /> },
        { path: 'customize-design', element: <CustomizeDesign /> }
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/app')));
const UserManagement = Loadable(lazy(() => import('../pages/dashboard/users')));
const OrderManagement = Loadable(lazy(() => import('../pages/dashboard/orders')));
const CardCategories = Loadable(lazy(() => import('../pages/dashboard/categoriesCard')));
const NewCardPage = Loadable(lazy(() => import('../pages/dashboard/categoriesCard/new')));
const OrdersDetails = Loadable(lazy(() => import('src/container/OrdersDetails')));
const EditCardId = Loadable(lazy(() => import('src/pages/dashboard/categoriesCard/editCard')));
const CardCategoriesDetails = Loadable(
  lazy(() => import('src/pages/dashboard/categoriesCard/[id]'))
);
const ShippingMethod = Loadable(lazy(() => import('src/pages/dashboard/shipping_method')));
const CardProfile = Loadable(lazy(() => import('src/pages/dashboard/card_profile')));
const AllProductsPage = Loadable(lazy(() => import('src/pages/dashboard/all_products')));
const SoicalMediaGroup = Loadable(lazy(() => import('src/pages/dashboard/social_media_group')));
const SocialMedia = Loadable(lazy(() => import('src/pages/dashboard/social_media_group/[id]')));
const StaffUsers = Loadable(lazy(() => import('src/pages/dashboard/staff-users')));
const ContactPage = Loadable(lazy(() => import('src/pages/dashboard/contact')));
const ColorsMaterialPage = Loadable(lazy(() => import('src/pages/dashboard/material-colors')));
const SettingPage = Loadable(lazy(() => import('src/pages/dashboard/setting')));
const NotificationsPage = Loadable(lazy(() => import('src/pages/dashboard/notifications')));
const MarketingEmailPage = Loadable(lazy(() => import('src/pages/dashboard/marketing_emails')));
const CustomizeDesign = Loadable(lazy(() => import('src/pages/dashboard/customize-design')))
// const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
