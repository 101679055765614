// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: getIcon('dashboard'),
  user: getIcon('user'),
  ecommerce: getIcon('car_tracfic'),
  analytics: getIcon('ic_analytics'),
  categories: getIcon('category_card'),
  card: getIcon('ic_card_profile'),
  shipping: getIcon('ic_shipping_method'),
  transaction: getIcon('trasanction'),
  products: getIcon(`ic_products`),
  social: getIcon(`social_media`),
  boss: getIcon(`boss`),
  contact: getIcon(`contact`),
  colors: getIcon(`colors`),
  setting: getIcon(`setting`),
  notification: getIcon(`notifications`),
  marketing_email: getIcon(`marketing_email`),
};
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard',
    items: [
      { title: 'Dashboard', path: '/dashboard/app', icon: ICONS.dashboard },
      { title: 'Users ', path: '/dashboard/users', icon: ICONS.user },
      { title: 'Staff Users', path: '/dashboard/staff_users', icon: ICONS.boss },
      { title: 'Card Links ', path: '/dashboard/card_profile', icon: ICONS.card },
      { title: 'Orders ', path: '/dashboard/orders', icon: ICONS.ecommerce },
      {
        title: 'Transactions ',
        path: '/dashboard/transactions',
        icon: ICONS.transaction,
      },
      { title: 'Materials - Colors', path: '/dashboard/material-colors', icon: ICONS.colors },
      { title: 'Card Categories', path: '/dashboard/card_categories', icon: ICONS.categories },
      { title: 'Customize Image', path: '/dashboard/customize-design', icon: ICONS.shipping },
      { title: 'Products', path: '/dashboard/all_products', icon: ICONS.products },
      // { title: 'Shipping Methods', path: '/dashboard/shipping_method', icon: ICONS.shipping },
      { title: 'Social Media', path: '/dashboard/social_media', icon: ICONS.social },
      { title: 'Contact', path: '/dashboard/contact', icon: ICONS.contact },
      // { title: 'Who Trust Me', path: '/dashboard/who-trust-me', icon: ICONS.who_trust_me },
      { title: 'Marketing Email', path: '/dashboard/marketing-email', icon: ICONS.marketing_email },
      { title: 'Push Notifications', path: '/dashboard/notifications', icon: ICONS.notification },
      { title: 'Settings', path: '/dashboard/company-setting', icon: ICONS.setting },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
